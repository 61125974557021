// import { useTranslation } from "react-i18next";

export function WhatsNew() {
//   const { t } = useTranslation();

  return (
    <section id="new" className="relative -z-10 mt-32 px-6 lg:px-8">
        <div className="mx-auto max-w-2xl text-center">
            {/* TBD */}
        </div>
    </section>
  );
}
