import { useTranslation } from "react-i18next";

export function Faqs({ className, ...props }) {
  const { t } = useTranslation();
  const faqs = [
    {
      id: 1,
      question: `${t("faqs:what-is-groomsome")}`,
      answer: `${t("faqs:what-is-groomsome-answer")}`,
    },
    {
      id: 2,
      question: `${t("faqs:how-does-the-pricing-work")}`,
      answer: `${t("faqs:how-does-the-pricing-work-answer")}`,
    },
    {
      id: 3,
      question: `${t("faqs:free-trial-available")}`,
      answer: `${t("faqs:free-trial-available-answer")}`,
    },
    {
      id: 4,
      question: `${t("faqs:how-do-i-cancel-my-account")}`,
      answer: `${t("faqs:how-do-i-cancel-my-account-answer")}`,
    },
    {
      id: 5,
      question: `${t("faqs:does-groomsome-offer-a-mobile-app")}`,
      answer: `${t("faqs:does-groomsome-offer-a-mobile-app-answer")}`,
    },
    {
      id: 6,
      question: `${t("faqs:can-i-use-groomsome-on-multiple-devices")}`,
      answer: `${t("faqs:can-i-use-groomsome-on-multiple-devices-answer")}`,
    },
    {
      id: 7,
      question: `${t("faqs:can-i-use-groomsome-on-multiple-locations")}`,
      answer: `${t("faqs:can-i-use-groomsome-on-multiple-locations-answer")}`,
    },
    {
      id: 8,
      question: `${t("faqs:why-do-I-need-to-setup-two-factor-authentication")}`,
      answer: `${t(
        "faqs:why-do-I-need-to-setup-two-factor-authentication-answer"
      )}`,
    },
  ];

  return (
    <section id="faqs"
      className="mx-auto mt-32 max-w-2xl divide-y divide-gray-900/10 px-6 pb-8 sm:pb-24 sm:pt-12 lg:max-w-7xl lg:px-8 lg:pb-32">
        <div className="text-center">
          <h2 className="text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">
            {t("faqs:frequently-asked-questions")}
          </h2>
        </div>

        <dl className="mt-10 space-y-8 divide-y divide-gray-900/10">
          {faqs.map((faq) => (
            <div key={faq.id} className="pt-8 lg:grid lg:grid-cols-12 lg:gap-8">
              <dt className="text-base font-semibold leading-7 text-gray-900 lg:col-span-5">
                {faq.question}
              </dt>
              <dd className="mt-4 lg:col-span-7 lg:mt-0">
                <p className="text-base leading-7 text-gray-600">
                  {faq.answer}
                </p>
              </dd>
            </div>
          ))}
        </dl>
    </section>
  );
}
