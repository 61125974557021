import { useTranslation } from "react-i18next";
import { PhoneFrame } from "./PhoneFrame";
import { AppScreen } from "./AppScreen";
import { Calendar } from "./appscreens/Calendar";

function AppDemo() {
  return (
    <AppScreen>
      <AppScreen.Body>
        <div className="p-4">
          <Calendar />
        </div>
      </AppScreen.Body>
    </AppScreen>
  )
}

export function Company({ className, ...props }) {
  const { t } = useTranslation();

  return (
    <section id="company" className="py-24 sm:py-32">
      
        <div className="mx-auto max-w-7xl px-6 lg:px-8">
          <div className="mx-auto max-w-2xl lg:mx-0 lg:max-w-none">
            <p className="text-base font-semibold leading-7 text-groomsome-600">
              GroomSome
            </p>
            <h1 className="mt-2 text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">
              {t('company:grooming-software-for-the-modern-pet-groomer')}
            </h1>
            <div className="mt-10 grid max-w-xl grid-cols-1 gap-8 text-base leading-7 text-gray-700 lg:max-w-none lg:grid-cols-2">
              <div>
                <p>
                  {t('company:groomsome-is-a-innovative')}
                </p>
                <p className="mt-8">
                  {t('company:what-sets-groomsome-apart')}
                </p>
                <p className="mt-8">
                  {t('company:in-summary-groomsome')}
                </p>
              </div>
              <div>
                <PhoneFrame className="mx-auto max-w-[366px]" priority>
                  <AppDemo />
                </PhoneFrame>
              </div>
            </div>
          </div>
        </div>
        
    </section>
  );
}
