import { useTranslation } from "react-i18next";

export function CallToAction() {
  const { t } = useTranslation();

  return (
    <div className="relative -z-10 mt-32 px-6 lg:px-8">
         
          <div className="mx-auto max-w-2xl text-center">
            <h2 className="text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">
              {t('callToAction:ready-to-get-started')}
              <br />
              {t('callToAction:get-started-today')}
            </h2>
            <p className="mx-auto mt-6 max-w-xl text-lg leading-8 text-gray-600">
              {t('callToAction:get-started-today-description')}
            </p>
            <div className="mt-10 flex items-center justify-center gap-x-6">
              <a
                href="https://my.groomsome.app"
                className="rounded-md bg-groomsome-600 px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-groomsome-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-groomsome-600"
              >
                  {t('callToAction:get-the-first-month-for-free')}
              </a>
              <a
                href="#faqs"
                className="text-sm font-semibold leading-6 text-gray-900"
              >
                {t('callToAction:learn-more')} <span aria-hidden="true">→</span>
              </a>
            </div>
          </div>
        </div>
  );
}
