import prices from "../pricing.json";

export function getSymbolForLanguage(language) {
  switch (language) {
    case "en-US":
      return "USD";
    case "fr-FR":
    case "it-IT":
    case "nl-NL":
    case "es-ES":
    case "de-DE":
    case "be-NL":
      return "EUR";
    default:
      return "USD";
  }
}

export function getLanguagePrice(feature, symbol) {
  if(prices[symbol] === undefined) return undefined;
  
  var price = prices[symbol][feature];
  return price ?? undefined;
}
