import { useTranslation } from "react-i18next";
import screenshotAppointments from '../images/screenshots/expenses.png'
import screenshotClientManagement from '../images/screenshots/payroll.png'
import screenshotProducts from '../images/screenshots/reporting.png'
import screenshotInvoicing from '../images/screenshots/vat-returns.png'
import { useId } from 'react'

export function PrimaryFeatures() {
  const { t } = useTranslation();
  const features = [
    {
      title: `${t('primaryFeatures:client-management')}`,
      description:`${t('primaryFeatures:client-management-description')}`,
      image: screenshotClientManagement,
      icon: function ReportingIcon() {
        let id = useId()
        return (
          <>
            <defs>
              <linearGradient
                id={id}
                x1="11.5"
                y1={18}
                x2={36}
                y2="15.5"
                gradientUnits="userSpaceOnUse"
              >
                <stop offset=".194" stopColor="#fff" />
                <stop offset={1} stopColor="#6692F1" />
              </linearGradient>
            </defs>
            <path
              d="m30 15-4 5-4-11-4 18-4-11-4 7-4-5"
              stroke={`url(#${id})`}
              strokeWidth={2}
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </>
        )
      },
    },
    {
      title: `${t('primaryFeatures:appointments')}`,
      description:`${t('primaryFeatures:appointments-description')}`,
      image: screenshotAppointments,
      icon: function ReportingIcon() {
        let id = useId()
        return (
          <>
            <defs>
              <linearGradient
                id={id}
                x1="11.5"
                y1={18}
                x2={36}
                y2="15.5"
                gradientUnits="userSpaceOnUse"
              >
                <stop offset=".194" stopColor="#fff" />
                <stop offset={1} stopColor="#6692F1" />
              </linearGradient>
            </defs>
            <path
              d="m30 15-4 5-4-11-4 18-4-11-4 7-4-5"
              stroke={`url(#${id})`}
              strokeWidth={2}
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </>
        )
      },
    },
    {
      title: `${t('primaryFeatures:invoices')}`,
      description:`${t('primaryFeatures:invoices-description')}`,
      image: screenshotInvoicing,
      icon: function ReportingIcon() {
        let id = useId()
        return (
          <>
            <defs>
              <linearGradient
                id={id}
                x1="11.5"
                y1={18}
                x2={36}
                y2="15.5"
                gradientUnits="userSpaceOnUse"
              >
                <stop offset=".194" stopColor="#fff" />
                <stop offset={1} stopColor="#6692F1" />
              </linearGradient>
            </defs>
            <path
              d="m30 15-4 5-4-11-4 18-4-11-4 7-4-5"
              stroke={`url(#${id})`}
              strokeWidth={2}
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </>
        )
      },
    },
    {
      title: `${t('primaryFeatures:product-management')}`,
      description:`${t('primaryFeatures:product-management-description')}`,
      image: screenshotProducts,
      icon: function ReportingIcon() {
        let id = useId()
        return (
          <>
            <defs>
              <linearGradient
                id={id}
                x1="11.5"
                y1={18}
                x2={36}
                y2="15.5"
                gradientUnits="userSpaceOnUse"
              >
                <stop offset=".194" stopColor="#fff" />
                <stop offset={1} stopColor="#6692F1" />
              </linearGradient>
            </defs>
            <path
              d="m30 15-4 5-4-11-4 18-4-11-4 7-4-5"
              stroke={`url(#${id})`}
              strokeWidth={2}
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </>
        )
      },
    },
  ]

  return (
    <section
      id="features"
      aria-label="Features for running your books"
      className="mx-auto pt-24 max-w-7xl px-6 sm:pt-32 lg:px-8"
    >
          <div className="mx-auto max-w-2xl lg:text-center">
            <h2 className="text-base font-semibold leading-7 text-groomsome-600">{t('primaryFeatures:experience-it')}</h2>
            <p className="mt-2 text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">
              {t('primaryFeatures:primary-features-title')}
            </p>
            <p className="mt-6 text-lg leading-8 text-gray-600">
              {t('primaryFeatures:primary-features-description')}
            </p>
          </div>
          <div className="mx-auto mt-16 max-w-2xl sm:mt-20 lg:mt-24 lg:max-w-4xl">
            <dl className="grid max-w-xl grid-cols-1 gap-x-8 gap-y-10 lg:max-w-none lg:grid-cols-2 lg:gap-y-16">
              {features.map((feature) => (
                <div key={feature.title} className="relative pl-16">
                  <dt className="text-base font-semibold leading-7 text-gray-900">
                    <div className="absolute left-0 top-0 flex h-10 w-10 items-center justify-center rounded-lg bg-groomsome-600">
                    <svg aria-hidden="true" className="h-9 w-9" fill="none">
                      <feature.icon />
                    </svg>
                    </div>
                    {feature.title}
                  </dt>
                  <dd className="mt-2 text-base leading-7 text-gray-600">{feature.description}</dd>
                </div>
              ))}
            </dl>
          </div>

    </section>
  )
}
