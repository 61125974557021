import { CheckIcon } from "@heroicons/react/20/solid";
import { PlusIcon, MinusIcon } from "@heroicons/react/20/solid";
import { useTranslation } from "react-i18next";
import { useState } from "react";
import { Switch } from "@headlessui/react";
import { getLanguagePrice, getSymbolForLanguage } from "../utils/pricing";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

export function Pricing() {
  const pricePerExtraUser = 5;
  const { t, i18n } = useTranslation();
  const [extraUsers, setExtraUsers] = useState(0);
  const [extraUsersPrice, setExtraUsersPrice] = useState(0);
  const [features, setFeatures] = useState(
    [
      {
        id: "clientManagement",
        order: 1,
        title: "client-management",
        description: "client-management-description",
        price: getLanguagePrice(
          "clientManagement",
          getSymbolForLanguage(i18n.language)
        ),
        enabled: true,
      },
      {
        id: "appointments",
        order: 2,
        title: "appointments",
        description: "appointments-description",
        price: getLanguagePrice(
          "appointments",
          getSymbolForLanguage(i18n.language)
        ),
        enabled: false,
      },
      {
        id: "invoicing",
        order: 3,
        title: "invoices",
        description: "invoices-description",
        price: getLanguagePrice(
          "invoicing",
          getSymbolForLanguage(i18n.language)
        ),
      },
      {
        id: "productManagement",
        order: 4,
        title: "product-management",
        description: "product-management-description",
        price: getLanguagePrice(
          "productManagement",
          getSymbolForLanguage(i18n.language)
        ),
      },
    ].sort((a, b) => (a.order > b.order ? 1 : -1))
  );

  const handleNumberOfExtraUsersChange = (newExtraUsers) => {
    if(newExtraUsers < 0) {
      return;
    }

    
    setExtraUsers(newExtraUsers);
    setExtraUsersPrice(newExtraUsers * pricePerExtraUser);
    if(newExtraUsers < extraUsers) {
      setTotalPrice((prevTotalPrice) => {
        return prevTotalPrice - pricePerExtraUser;
      });
    } else {
      setTotalPrice((prevTotalPrice) => {
        return prevTotalPrice + pricePerExtraUser;
      });
    }
  };

  const handleToggle = (feature) => {
    feature.enabled = !feature.enabled;
    setFeatures(
      [...features.filter((f) => f.id !== feature.id), feature].sort((a, b) =>
        a.order > b.order ? 1 : -1
      )
    );

    setTotalPrice((prevTotalPrice) => {
      if (feature.enabled) {
        return prevTotalPrice + feature.price;
      }
      return prevTotalPrice - feature.price;
    });
  };

  const [totalPrice, setTotalPrice] = useState(() => {
    return features.reduce((acc, feature) => {
      if (feature.enabled) {
        return acc + feature.price;
      }
      return acc;
    }, 0);
  });

  return (
    <section id="pricing" className="py-24 sm:pt-48">
      <div className="mx-auto max-w-5xl px-6 lg:px-8">
        <div className="mx-auto max-w-2xl sm:text-center">
          <h2 className="text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">
            {t("pricing:simple-no-tricks-pricing")}
          </h2>
          <p className="mt-6 text-lg leading-8 text-gray-600">
            {t("pricing:pricing-description")}
          </p>
        </div>
        <div className="mx-auto mt-16 max-w-2xl rounded-3xl ring-1 ring-gray-200 sm:mt-20 lg:mx-0 lg:flex lg:max-w-none">
          <div className="p-8 sm:p-10 lg:flex-auto">
            {features.map((feature) => (
              <Switch.Group
                as="div"
                className="flex items-center justify-between mt-6"
              >
                <span className="flex flex-grow flex-col">
                  <Switch.Label
                    as="span"
                    className="text-sm font-medium leading-6 text-gray-900"
                    passive
                  >
                    {t(`primaryFeatures:${feature.title}`)}
                  </Switch.Label>
                  <Switch.Description
                    as="span"
                    className="text-sm text-gray-500 pr-3"
                  >
                    {t(`primaryFeatures:${feature.description}`)}
                  </Switch.Description>
                </span>
                <div class="">
                  <div class="flex justify-end">
                    <span className="text-sm font-medium text-gray-900">
                      {feature.price.toLocaleString(i18n.language, {
                        style: "currency",
                        currency: getSymbolForLanguage(i18n.language),
                      })}
                    </span>
                </div>
                <li key={feature.id} className="flex gap-x-3">
                  {feature.enabled ? (
                    <CheckIcon
                      className="h-6 w-5 flex-none text-groomsome-600"
                      aria-hidden="true"
                    />
                  ) : (
                    <span className="h-6 w-5 flex-none text-groomsome-600" />
                  )}

                  <Switch
                    checked={feature.enabled}
                    onChange={() => handleToggle(feature)}
                    className={classNames(
                      feature.enabled ? "bg-groomsome-600" : "bg-gray-200",
                      "relative inline-flex h-6 w-11 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-groomsome-600 focus:ring-offset-2"
                    )}
                  >
                    <span
                      aria-hidden="true"
                      className={classNames(
                        feature.enabled ? "translate-x-5" : "translate-x-0",
                        "pointer-events-none inline-block h-5 w-5 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out"
                      )}
                    />
                  </Switch>
                </li>
                </div>
              </Switch.Group>
            ))}

            <div className="flex items-center justify-between mt-6">
              <div className="flex flex-grow flex-row items-center">
                <span className="text-sm font-medium leading-6 text-gray-900">
                  <span className="mr-4">
                    <span className="mr-4">
                    {t("pricing:extra-employees")} 
                    </span>
                    <span >
                    ({pricePerExtraUser.toLocaleString(i18n.language, {
                      style: "currency",
                      currency: getSymbolForLanguage(i18n.language),
                    })} {t("pricing:per-month")})
                    </span>
                 
                  </span>
                  <span className="inline-flex items-center rounded-md bg-indigo-50 px-2 py-1 text-xs font-medium text-groomsome-700 ring-1 ring-inset ring-indigo-700/10">
                  {extraUsers} {t("pricing:employeesnr")}
                  </span>
                </span>
                <div
                  className={classNames(
                    "relative inline-flex h-9 w-11 flex-shrink-0"
                  )}
                >
                </div>
              </div>

              <div>

              <span className="mr-2 flex justify-end text-sm font-medium leading-6 text-gray-900">
                {extraUsersPrice.toLocaleString(i18n.language, {
                  style: "currency",
                  currency: getSymbolForLanguage(i18n.language),
                })}
              </span>
              <li key="dassda" className="flex gap-x-3">
                <span className="h-6 w-5 flex-none text-groomsome-600" />
                <div
                  className={classNames(
                    "relative inline-flex h-9 w-21 flex-shrink-0"
                  )}
                >
                  <span className="isolate inline-flex rounded-md shadow-sm">
                    <button
                      type="button"
                      onClick={() => handleNumberOfExtraUsersChange(extraUsers - 1)}
                      className="relative inline-flex items-center rounded-l-md bg-white px-2 py-2 text-gray-400 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:z-10"
                    >
                      <span className="sr-only">Previous</span>
                      <MinusIcon className="h-5 w-5" aria-hidden="true" />
                    </button>

                    <button
                      type="button"
                      onClick={() => handleNumberOfExtraUsersChange(extraUsers + 1)}
                      className="relative -ml-px inline-flex items-center rounded-r-md bg-white px-2 py-2 text-gray-400 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:z-10"
                    >
                      <span className="sr-only">Next</span>
                      <PlusIcon className="h-5 w-5" aria-hidden="true" />
                    </button>
                  </span>
                </div>
              </li>
              </div>
            </div>
            

            <div className="flex items-center justify-between mt-6">
              <div className="flex flex-grow flex-col ">
                <span className="text-sm font-medium leading-6 text-gray-900">
                  {t("pricing:total-price")}:
                </span>
              </div>
              <span className="mr-5 text-sm font-medium leading-6 text-gray-900">
                {totalPrice.toLocaleString(i18n.language, {
                  style: "currency",
                  currency: getSymbolForLanguage(i18n.language),
                })}
              </span>
              <li key="dassa" className="flex gap-x-3">
                <span className="h-6 w-5 flex-none text-groomsome-600" />
                <div
                  className={classNames(
                    "relative inline-flex h-6 w-11 flex-shrink-0"
                  )}
                ></div>
              </li>
            </div>

            <div className="flex items-center justify-between mt-6">
              <a
                href="https://my.groomsome.app"
                className="ml-auto rounded-md bg-groomsome-600 px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-groomsome-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-groomsome-600"
              >
                {t("common:get-started")}
              </a>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
