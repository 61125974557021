import { Dialog } from "@headlessui/react";
import { Bars3Icon, XMarkIcon } from "@heroicons/react/24/outline";
import { useState, useEffect } from "react";
import groomsomeLogo from "../images/logos/full-logo.svg";
import { useTranslation } from "react-i18next";

export function Header({ className, ...props }) {
  const { t, i18n } = useTranslation();
  const [isOpen, setIsOpen] = useState(false);

  const navigation = [
    { name: `${t("header:features")}`, href: "#features" },
    { name: `${t("header:pricing")}`, href: "#pricing" },
    { name: `${t("header:company")}`, href: "#company" },
    { name: `${t("header:faq")}`, href: "#faqs" },
    // { name: `${t("header:new")}`, href: "#new" },
  ];

  const languages = [
    { name: "English", key: "en-US", short: "gb" },
    { name: "Nederlands", key: "nl-NL", short: "nl" },
  ];

  const selectedLanguage =
    languages.find((language) => language.key === i18n.language) ??
    languages[0];
  const handleLanguageChange = async (language) => {
    await i18n.changeLanguage(language.key);
    setIsOpen(false);
  };

  const LANGUAGE_SELECTOR_ID = "language-selector";
  useEffect(() => {
    const handleWindowClick = (event) => {
      const target = event.target.closest("button");
      if (target && target.id === LANGUAGE_SELECTOR_ID) {
        return;
      }
      setIsOpen(false);
    };
    window.addEventListener("click", handleWindowClick);
    return () => {
      window.removeEventListener("click", handleWindowClick);
    };
  }, []);

  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);
  return (
    <header className="fixed inset-x-0 top-0 z-50 bg-white border-b-2">
      <nav
        className="flex items-center justify-between p-6 lg:px-8"
        aria-label="Global"
      >
        <div className="flex lg:flex-1">
          <a href="/" className="-m-1.5 p-1.5">
            <span className="sr-only">GroomSome</span>
          </a>
        </div>
        <div className="flex lg:hidden">
          <button
            type="button"
            className="-m-2.5 inline-flex items-center justify-center rounded-md p-2.5 text-gray-700"
            onClick={() => setMobileMenuOpen(true)}
          >
            <span className="sr-only">Open main menu</span>
            <Bars3Icon className="h-6 w-6" aria-hidden="true" />
          </button>
        </div>
        <div className="hidden lg:flex lg:gap-x-12">
          {navigation.map((item) => (
            <a
            key={item.name}
            href={item.href}
            className="text-sm font-semibold leading-6 text-gray-900"
          >
            {item.name}
          </a>
          ))}
        </div>

        <div className="hidden lg:flex lg:flex-1 lg:justify-end">
          <div className="mr-10">
            <button
              onClick={() => setIsOpen(!isOpen)}
              type="button"
              className="inline-flex items-center justify-center w-full px-4 py-2 text-sm font-medium  text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-groomsome-500"
              id={LANGUAGE_SELECTOR_ID}
              aria-expanded={isOpen}
            >
              <span
                className={`fi fis fi-${selectedLanguage.short} rounded-md mr-2`}
              />
              {selectedLanguage.name}
              <svg
                className="-mr-1 ml-2 h-5 w-5"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 20 20"
                fill="currentColor"
                aria-hidden="true"
              >
                <path
                  fillRule="evenodd"
                  d="M10.293 14.707a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L10 12.586l3.293-3.293a1 1 0 011.414 1.414l-4 4z"
                  clipRule="evenodd"
                />
              </svg>
            </button>
            {isOpen && (
              <div
                className="origin-top-right absolute right-0 mt-2 w-96 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5"
                role="menu"
                aria-orientation="vertical"
                aria-labelledby={LANGUAGE_SELECTOR_ID}
              >
                <div className="py-1 grid grid-cols-2 gap-2" role="none">
                  {languages.map((language, index) => {
                    return (
                      <button
                        key={language.key}
                        onClick={() => handleLanguageChange(language)}
                        className={`${
                          selectedLanguage.key === language.key
                            ? "bg-gray-100 text-gray-900"
                            : "text-gray-700"
                        } block px-4 py-2 text-sm text-left items-center inline-flex hover:bg-gray-100 ${
                          index % 2 === 0 ? "rounded-r" : "rounded-l"
                        }`}
                        role="menuitem"
                      >
                        <span className={`fi fis fi-${language.short} mr-2`} />
                        <span className="truncate">{language.name}</span>
                      </button>
                    );
                  })}
                </div>
              </div>
            )}
          </div>
          <a
            href="https://my.groomsome.app"
            className="text-sm font-semibold leading-6 mt-2 text-gray-900"
          >
            {t("header:log-in")} <span aria-hidden="true">&rarr;</span>
          </a>
        </div>
      </nav>
      <Dialog
        as="div"
        className="lg:hidden"
        open={mobileMenuOpen}
        onClose={setMobileMenuOpen}
      >
        <div className="fixed inset-0 z-50" />
        <Dialog.Panel className="fixed inset-y-0 right-0 z-50 w-full overflow-y-auto bg-white px-6 py-6 sm:max-w-sm sm:ring-1 sm:ring-gray-900/10">
          <div className="flex items-center justify-between">
            <a href="https://www.groomsome.app" className="-m-1.5 p-1.5 focus:outline-none focus:ring-0">
              <span className="sr-only">GroomSome</span>
              <img className="h-32 w-auto focus:outline-none focus:ring-0" src={groomsomeLogo} alt="" />
            </a>
            <button
              type="button"
              className="-m-2.5 rounded-md p-2.5 text-gray-700"
              onClick={() => setMobileMenuOpen(false)}
            >
              <span className="sr-only">Close menu</span>
              <XMarkIcon className="h-6 w-6" aria-hidden="true" />
            </button>
          </div>
          <div className="mt-6 flow-root">
            <div className="-my-6 divide-y divide-gray-500/10">
              <div className="space-y-2 py-6">
                <ul>
                  {navigation.map((item) => (
                    <li onClick={() => setMobileMenuOpen(false)}>
                      <a
                        key={item.name}
                        href={item.href}
                        className="-mx-3 block rounded-lg px-3 py-2 text-base font-semibold leading-7 text-gray-900 hover:bg-gray-50"
                      >
                        {item.name}
                      </a>
                    </li>
                  ))}
                </ul>
              </div>

              <div className="py-1 grid grid-cols-2 gap-2" role="none">
                {languages.map((language, index) => {
                  return (
                    <button
                      key={language.key}
                      onClick={() => handleLanguageChange(language)}
                      className={`${
                        selectedLanguage.key === language.key
                          ? "bg-gray-100 text-gray-900"
                          : "text-gray-700"
                      } block px-4 py-2 text-sm text-left items-center inline-flex hover:bg-gray-100 ${
                        index % 2 === 0 ? "rounded-r" : "rounded-l"
                      }`}
                      role="menuitem"
                    >
                      <span className={`fi fis fi-${language.short} mr-2`} />
                      <span className="truncate">{language.name}</span>
                    </button>
                  );
                })}
              </div>
              <div className="py-6">
                <a
                  href="https://my.groomsome.app"
                  className="-mx-3 block rounded-lg px-3 py-2.5 text-base font-semibold leading-7 text-gray-900 hover:bg-gray-50"
                >
                  {t("header:log-in")} <span aria-hidden="true">&rarr;</span>
                </a>
              </div>
            </div>
          </div>
        </Dialog.Panel>
      </Dialog>
    </header>
  );
}
